<template>
  <div class="flex-1 cursor-auto">
    <div class="cursor-pointer inline">
      <icon-base
        :data-cy="'offer-shared-icon-' + icon"
        :icon="icon"
        :class="htmlClass"
        @click.stop="togleOfferShared"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { IOffer } from "@/models/funders";
import type { PropType } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  model: {
    type: Object as PropType<IOffer>,
    required: true
  }
});

const { dispatch } = useStore();

const icon = computed(() =>
  props.model.is_shared ? "show-pass-no-bg" : "show-pass"
);
const htmlClass = computed(() =>
  props.model.is_shared ? "text-gray-500" : "text-disabled-medium"
);

const togleOfferShared = async () => {
  await dispatch("applications/toggleShowOffer", props.model.id);
};
</script>
